import { EntryStatus, EntryType, TransferBooking } from "@prisma/client";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import queryClient from "lib/clients/react-query";
import { fetcher } from "lib/fetcher";
import isUserBookedOnEntry from "lib/isUserBookedOnEntry";
import { getConnectedEntries } from "lib/prismaQueries/getConnectedEntries";
import { useSession } from "next-auth/react";
import useTranslation from "next-translate/useTranslation";
import { useEffect, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import getBookedIdForUser from "lib/getBookedIdForUser";
import {
  ArchiveBoxArrowDownIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  LockClosedIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

import { Price } from "./Price";
import { AllBookingsCountConnected } from "./AllBookingsCountConnected";
import { DialogV2 } from "components/Dialog/DialogV2";
import { Button } from "components/CustomButton";
import { LoadingIcon } from "components/Icons/LoadingIcon";
import { UserTableNew } from "components/UserTable/UserTableNew";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast, ToastContainer } from "react-toastify";
import { useRouter } from "next/router";
import { BookableUntil } from "components/Post/components/BookableUntil";
import BookedTimes from "./BookedTimes";

type Props = {
  entry: NonNullable<
    Awaited<ReturnType<typeof getConnectedEntries>>
  >["entries"][number];
  userId?: number;
  connectedEntryId?: number;
  remainingBookings?: number;
  etype: EntryType;
  archived?: boolean;
  reducedPrice?: boolean;
  hideVariant?: boolean;
};

export const SinglePost = ({
  entry,
  userId,
  connectedEntryId,
  remainingBookings,
  etype,
  archived,
  reducedPrice,
  hideVariant = false,
}: Props) => {
  const { data: session } = useSession();
  const { t, lang } = useTranslation("post");
  const router = useRouter();
  const { bookedCount, isBooked } = isUserBookedOnEntry(
    session?.user?.id,
    entry?.BookEntry as { userId: number }[] | undefined
  );

  const bookingId = getBookedIdForUser(
    session?.user?.id,
    entry?.BookEntry as { userId: number; id: number }[] | undefined
  );

  //states
  const [bookButtonDisabled, setBookButtonDisabled] = useState(false);
  const [cannotBeBooked, setCannotBeBooked] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [dayIsOver, setDayIsOver] = useState(false);
  const [bookMoreModal, setBookMoreModal] = useState(false);
  const [deleteBookingModal, setDeleteBookingModal] = useState(false);
  const [transferBookingModal, setTransferBookingModal] = useState(false);
  const [transferHashID, setTransferHashID] = useState("");
  const [wasCopied, setWasCopied] = useState(false);
  const [slotsRemaining, setSlotsRemaining] = useState(true);

  //useEffects
  useEffect(() => {
    const dateOfEntry = dayjs(entry.bookDate).hour(0).minute(0); //normalize date to 00:00 to compare
    const dayDifference = dateOfEntry.diff(dayjs(), "day", true);
    if (dayDifference < 0) {
      setDayIsOver(true);
    }
  }, [entry.bookDate]);

  useEffect(() => {
    const dateOfAutoclose = dayjs(entry.autocloseDate);
    const difference = dateOfAutoclose.diff(dayjs(), "minute", true);
    if (difference < 0) {
      setCannotBeBooked(true);
    }
  }, [entry.autocloseDate, etype, remainingBookings]);

  useEffect(() => {
    const areSlotsRemaining =
      etype === EntryType.CAPPED ? remainingBookings! > 0 : true;
    if (areSlotsRemaining === false) {
      setSlotsRemaining(false);
    } else {
      setSlotsRemaining(true);
    }
  }, [etype, remainingBookings]);

  //mutations
  const bookMutation = useMutation({
    mutationFn: (id: number) =>
      fetcher(`/api/book/${id}/${lang}`, { method: "POST" }),
    onSuccess: () => {
      setBookButtonDisabled(false);
      queryClient.invalidateQueries({ queryKey: ["entries"] });
      queryClient.invalidateQueries({ queryKey: ["events"] });
      queryClient.invalidateQueries({ queryKey: ["user", session?.user?.id] });
      if (session?.user?.benefits) {
        queryClient.invalidateQueries({
          queryKey: ["benefits", session?.user?.id],
        });
      }
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (id: number) =>
      fetcher(`/api/book/${id}`, { method: "DELETE" }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["entries"] });
      queryClient.invalidateQueries({ queryKey: ["events"] });
      if (session?.user?.benefits) {
        queryClient.invalidateQueries({
          queryKey: ["benefits", session?.user?.id],
        });
      }
    },
  });

  const transferMutation = useMutation({
    mutationFn: (id: number) =>
      fetcher<TransferBooking>(`/api/transfer/${id}`, { method: "POST" }),
    onSuccess: (data) => {
      setTransferHashID(data.hashID);
      queryClient.invalidateQueries({ queryKey: ["entries"] });
    },
  });

  const archiveMutation = useMutation({
    mutationFn: (id: number) =>
      fetcher(`/api/archive/${id}`, {
        method: "PUT",
      }),
    onSettled: () => {
      notify();
      queryClient.invalidateQueries({ queryKey: ["entries"] });
    },
  });

  const bookIt = (id: number) => {
    setBookButtonDisabled(true);
    bookMutation.mutate(id);
  };

  const deleteIt = async (id: number): Promise<void> => {
    setBookButtonDisabled(true);
    deleteMutation.mutate(id);
    setBookButtonDisabled(false);
  };

  const notificationMessage = t("archivedNotification");
  const notify = () =>
    toast(notificationMessage, {
      icon: "📂",
    });

  return (
    <div className="mb-10">
      <div
        className={clsx(
          "relative mt-8 rounded-t border-2",
          bookedCount > 0
            ? "border-x-emerald-400 border-b-slate-300 border-t-emerald-400 dark:border-x-emerald-700 dark:border-b-slate-700 dark:border-t-emerald-700"
            : "border-slate-300 dark:border-slate-700"
        )}
      >
        <div className="flex items-stretch">
          {!hideVariant ? (
            <div className="absolute top-[-18px] bg-slate-200 px-2 font-semibold text-indigo-600 md:text-xl dark:bg-slate-800 dark:text-fuchsia-600">
              {t(entry?.variants[0]?.Variants.name)}
            </div>
          ) : null}
          <div className="flex items-center border-r-2 border-slate-300 p-2 dark:border-slate-700">
            <AllBookingsCountConnected
              count={entry?.BookEntry?.length}
              capping={entry?.capping}
              etype={entry?.capping ? "CAPPED" : "OPEN"}
            />
          </div>
          <div className="grow px-4 py-6">
            <h3 className="text-lg font-semibold dark:text-slate-300">
              {entry?.title}
              {entry?.bookDate && !!hideVariant ? (
                <span className="ml-2 text-sm font-normal text-slate-500 dark:text-slate-400">
                  {dayjs(entry.bookDate).format("HH:mm")}
                </span>
              ) : null}
            </h3>
            {entry.content ? (
              <ReactMarkdown
                className="col-start-1 mt-1 text-sm leading-6 text-slate-500 sm:col-span-2 md:text-base lg:col-span-1 lg:row-start-4 lg:mt-1 dark:text-slate-400 [&>p]:mb-3"
                components={{
                  a: ({ href, children }) => (
                    <a
                      href={href}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-indigo-600 underline transition-all dark:text-fuchsia-600"
                    >
                      {children}
                    </a>
                  ),
                }}
              >
                {entry.content}
              </ReactMarkdown>
            ) : null}
            <div className="mt-3">
              {entry?.autoclose && entry?.autocloseDate && session ? (
                <BookableUntil
                  aDate={entry?.autocloseDate}
                  locale={router.locale}
                  shownText="bookableUntil"
                />
              ) : null}
              {session ? <BookedTimes times={bookedCount} /> : null}
            </div>
          </div>
          <div className="flex items-center border-l-2 border-slate-300 p-2 dark:border-slate-700">
            {entry.price ? (
              <Price price={entry.price} reduced={reducedPrice} />
            ) : null}
          </div>
        </div>
      </div>
      {session && (
        <>
          <div>
            {transferHashID ? (
              <div
                className={clsx(
                  " flex items-center  border-x-2 border-b-2 ",
                  bookedCount > 0
                    ? "border-x-emerald-400 border-b-slate-300 border-t-emerald-400 dark:border-x-emerald-700 dark:border-b-slate-700 dark:border-t-emerald-700"
                    : "border-slate-300 dark:border-slate-700"
                )}
              >
                <div className="mx-3 text-sm text-slate-500 dark:text-slate-300">
                  {t("copyLink")}
                </div>
                <div
                  onMouseOut={() => setWasCopied(false)}
                  className="group relative my-0  inline-flex grow flex-row items-center rounded-sm  bg-slate-300 dark:bg-slate-600"
                >
                  <ChevronRightIcon
                    className="-mr-1 ml-2 mt-0 h-4 w-4  text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                  <input
                    type="text"
                    disabled
                    className="w-full overflow-ellipsis border-0 bg-transparent py-1 text-sm text-slate-400"
                    value={`${process.env.NEXT_PUBLIC_API_URL}/transfer?transferId=${transferHashID}`}
                  ></input>

                  <span className="mr-3 inline-block w-3 text-slate-500 dark:text-slate-400">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M21 10v10a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1zM6 14H5V5h9v1a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h2a1 1 0 0 0 0-2z"
                      />
                    </svg>
                  </span>
                  <span className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center bg-emerald-100 text-sm text-slate-500 opacity-0 transition-all group-hover:opacity-100 dark:text-slate-400 ">
                    {wasCopied
                      ? `${t("copiedClipboard")}`
                      : `${t("copyClipboard")}`}
                  </span>
                  <CopyToClipboard
                    onCopy={() => setWasCopied(true)}
                    text={`${process.env.NEXT_PUBLIC_API_URL}/transfer?transferId=${transferHashID}`}
                  >
                    <span className="absolute bottom-0 left-0 right-0 top-0 mr-3 cursor-pointer text-sm text-slate-500 dark:text-slate-400"></span>
                  </CopyToClipboard>
                </div>
              </div>
            ) : null}
          </div>

          <div
            className={clsx(
              "z-2 relative mt-[-2px] flex rounded-b border-x-2 border-b-2 pt-0.5",
              bookedCount > 0
                ? "border-x-emerald-400 border-b-emerald-400 dark:border-x-emerald-700 dark:border-b-emerald-700 "
                : "border-slate-300 dark:border-slate-700"
            )}
          >
            {session && entry.bookopen && !cannotBeBooked && !archived ? (
              <>
                <span className="inline-block border-r-2 border-slate-300 dark:border-slate-700">
                  {(entry.BookEntry.length < entry?.capping ||
                    entry?.etype === EntryType.OPEN) &&
                  slotsRemaining ? (
                    <Button
                      click={() =>
                        bookedCount > 0
                          ? setBookMoreModal(true)
                          : bookIt(entry.id)
                      }
                      disabled={bookButtonDisabled}
                      onPost
                    >
                      {bookButtonDisabled ? (
                        <>
                          <LoadingIcon />{" "}
                          <span className="invisible">
                            {" "}
                            {t("bookItButton")}
                          </span>
                        </>
                      ) : (
                        <span> {t("bookItButton")}</span>
                      )}
                    </Button>
                  ) : null}
                </span>
                {bookedCount > 0 ? (
                  <>
                    <span className="inline-block border-r-2 border-slate-300 text-center dark:border-slate-700">
                      <Button onPost click={() => setDeleteBookingModal(true)}>
                        <span>
                          <TrashIcon
                            className=" -mt-1 inline h-5 w-5  text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-1 hidden md:inline">
                          {t("Delete Booking")}
                        </span>
                      </Button>
                    </span>
                  </>
                ) : null}
              </>
            ) : null}

            {(session && !entry.bookopen && !archived) ||
            (session && cannotBeBooked && !archived) ||
            (entry.BookEntry.length >= entry?.capping &&
              entry?.etype === EntryType.CAPPED &&
              !archived) ? (
              <span className="flex items-center px-3 py-1 text-xs  text-slate-500 md:text-sm dark:text-slate-300 ">
                <span>
                  <LockClosedIcon
                    className="-mt-1 mr-1 inline h-5 w-5  text-red-600 hover:text-violet-100"
                    aria-hidden="true"
                  />
                </span>
                <span className="ml-1 hidden md:inline">
                  {t("Booking is closed")}
                </span>
              </span>
            ) : null}
            {(isBooked && bookedCount < 2 && !entry.bookopen) ||
            (session &&
              cannotBeBooked &&
              bookedCount < 2 &&
              isBooked &&
              !archived) ? (
              <span className="inline-block border-r-2 border-slate-300 dark:border-slate-700">
                <Button onPost click={() => setTransferBookingModal(true)}>
                  {t("transfer")}
                </Button>
              </span>
            ) : null}
            {session?.user?.admin ? (
              <span className="inline-block border-r-2 border-slate-300 dark:border-slate-700">
                <Button isFakeButton href={`/entry/${entry.id}`} onPost>
                  <span>
                    <PencilSquareIcon
                      className="-mt-1 mr-1 inline h-5 w-5  text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-1 hidden md:inline">
                    {t("editDetailsButton")}
                  </span>
                </Button>
              </span>
            ) : null}
            {session?.user?.admin &&
            dayIsOver &&
            entry.status !== EntryStatus.ARCHIVED &&
            connectedEntryId === Infinity ? (
              <span className="inline-block border-r-2 border-slate-300 dark:border-slate-700">
                <Button
                  onPost
                  click={() => archiveMutation.mutate(entry.id)}
                  additionalClassNames="bg-rose-100 dark:bg-rose-100 !text-black hover:text-white"
                >
                  <span>
                    <ArchiveBoxArrowDownIcon
                      className="-mt-1 mr-1 inline h-5 w-5  text-red-800 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-1 hidden md:inline">
                    {t("archiveButton")}
                  </span>
                </Button>
              </span>
            ) : null}
            {entry?.BookEntry?.length > 0 ? (
              <span className="ml-auto inline-flex border-l-2 border-slate-300 dark:border-slate-700">
                <Button
                  additionalClassNames="inline-flex"
                  onPost
                  click={() => setShowTable(!showTable)}
                >
                  <span>{entry?.BookEntry?.length}</span>
                  <ChevronDownIcon
                    className={clsx(
                      "-mr-1 ml-2 mt-1 h-4 w-4 text-violet-200 transition-transform hover:text-violet-100",
                      showTable ? "rotate-180" : "rotate-0"
                    )}
                    aria-hidden="true"
                  />
                </Button>
              </span>
            ) : null}
          </div>

          {session && showTable ? (
            <UserTableNew bookEntry={entry.BookEntry} />
          ) : null}
        </>
      )}

      {/* This is the Dialog for adding more than one booking */}
      <DialogV2
        isOpen={bookMoreModal}
        setIsOpen={setBookMoreModal}
        title={t("confirmBookMoreBooking")}
        click={() => bookIt(entry.id)}
      >
        <p className="mt-1">
          {t("You are already booked")}{" "}
          <span className=" font-bold">
            {bookedCount} {bookedCount === 1 ? t("time") : t("times")}
          </span>{" "}
          {t("for")} {entry.title}{" "}
          {new Intl.DateTimeFormat(router.locale, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }).format(new Date(entry?.bookDate))}{" "}
          {t("bookMoreConfirm")}?
        </p>
      </DialogV2>

      {/* This is the Dialog for deleting a booking */}
      <DialogV2
        isOpen={deleteBookingModal}
        setIsOpen={setDeleteBookingModal}
        title={t("confirmDeleteBooking")}
        click={() => deleteIt(entry.id)}
      >
        <p className="mt-2">{t("deleteConfirmation")} </p>
        <p className="mt-1 font-bold">
          {bookedCount} {bookedCount === 1 ? t("time") : t("times")} {t("for")}{" "}
          {new Intl.DateTimeFormat(router.locale, {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          }).format(new Date(entry?.bookDate))}
          .
        </p>
        <p className="mt-3">{t("continueConfirm")}</p>
      </DialogV2>

      {/* This is the Dialog for transfer a booking */}
      <DialogV2
        isOpen={transferBookingModal}
        setIsOpen={setTransferBookingModal}
        title={t("confirmTransferBooking")}
        click={() => transferMutation.mutate(bookingId)}
      >
        <p className="mt-1">{t("transferMessage")} </p>
        <p className="mt-2">
          <span className=" font-bold">{entry.title}</span>{" "}
          <span className=" font-bold">
            {new Intl.DateTimeFormat(router.locale, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }).format(new Date(entry?.bookDate))}
          </span>
        </p>
      </DialogV2>
    </div>
  );
};

export default SinglePost;
